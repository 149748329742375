import { Link, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {
  CgBriefcase,
  CgGlobeAlt,
  CgOrganisation,
  CgPen,
  CgReadme,
  CgTrees,
  CgTv,
  CgUserList,
} from "react-icons/cg"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function IndexRoute({ data, path }: PageProps) {
  return (
    <Layout>
      <SEO title="Creative Intelligence" />
      <header className="bg-blue-500 relative">
        <div className="absolute w-full h-full">
          {/* <Img fluid={data.hero.childImageSharp.fluid} className="min-h-80vh w-full" /> */}

          {/* <div className="absolute top-0 bg-gradient-to-r from-blue-500 to-blue-800 opacity-90 z-10 w-full h-full"></div> */}
        </div>
        <div className="container px-8 flex flex-col md:flex-row justify-between relative pb-16">
          <div className="w-full md:w-1/2 mt-10 md:mt-auto pb-8">
            <h1 className="text-4xl md:text-6xl font-bold text-white tracking-tight md:mb-4">
              Creative Intelligence for Business Growth
              <span className="text-red-500">.</span>
            </h1>
            <p className="text-lg md:text-2xl md:leading-normal font-light text-blue-100 mb-4">
              Our primary strength is creative intelligence, a merger of
              creativity with the analytical ability to deliver out-of-the-box
              results.
            </p>
            <Link
              to="/#contact"
              className="inline-block font-semibold bg-blue-500 hover:bg-blue-200 text-white border-2 border-white text-lg py-4 px-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-500 focus:ring-blue-100"
            >
              Contact Us
            </Link>
          </div>
          <div className="w-full md:w-1/2">
            <StaticImage
              src="../images/hero.png"
              layout="fullWidth"
              alt=""
              placeholder="tracedSVG"
              className="h-full w-full"
            />
          </div>
        </div>
      </header>

      {/*  */}
      <section className="bg-blue-50 pt-8 md:pt-16" id="who">
        <div className="container flex flex-col md:flex-row px-8 py-16 md:py-32">
          <div className="md:w-2/4">
            <h2 className="text-3xl md:text-5xl md:leading-tight font-bold mb-2 md:pr-8">
              A Digitally Powered, Creatively-inspired Consulting Organization
              <span className="text-red-500">.</span>
            </h2>
          </div>
          <div className="md:w-2/4">
            <p className="text-2xl md:text-2xl leading-normal md:leading-normal font-light mb-2">
              Paraclete Consulting Limited is an innovative integration firm
              that specializes in Digital Transformation, IT Modernization and
              Enterprise Solutions.
            </p>
            <p className="prose prose-xl text-gray-500">
              We help you achieve tangible, high impact results with high net
              worth individuals, partners, corporate organisations, NGO's and
              all levels of Government through well defined processes.
            </p>
          </div>
        </div>
      </section>

      <section className="relative bg-blue-50 min-h-[70vh] mb-8 md:mb-16">
        <div className="container">
          <StaticImage
            src="../images/nasa.jpg"
            layout="fullWidth"
            alt=""
            placeholder="tracedSVG"
            className="h-[70vh] w-full"
          />
        </div>
      </section>

      {/*  */}
      <section className="container px-8 py-16 md:py-32" id="what">
        <div className="flex flex-col md:flex-row ">
          <div className="md:w-2/3 mx-auto md:text-center">
            <h2 className="text-3xl md:text-5xl md:leading-tight font-bold capitalize">
              We bring deep expertise, professional services, knowledge and
              practical industry experience
              <span className="text-red-500">.</span>
            </h2>
          </div>
        </div>
        <div className="grid md:grid-cols-3 mt-8 md:mt-16 gap-6">
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgOrganisation size="1.55em" />
            </span>
            <p className="font-semibold text-2xl">Real Estate</p>
            <p className="text-gray-500 text-xl">
              Marketing, Sales of Office Spaces, Residential and Commercial
              Spaces
            </p>
            <p className="text-gray-500 text-xl">
              Individual Rent To Own and Mortgage Services
            </p>
            <p className="text-gray-500 text-xl">
              Housing Solutions For; Cooperatives, NGO'S, Corporate Group
              Packages, Diplomatic Community, Military and Other Customized
              Requests
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgTrees size="1.65em" />
            </span>
            <p className="font-semibold text-2xl">Business Development</p>
            <p className="text-gray-500 text-xl">
              Business Registration, Business Plans, Grants, Loans, Value
              Creation through Data Generation and Analytics, Problem Solving By
              Providing Unique Business Ideas, Solutions, Concepts,
              Implementation, Monitoring, Coordination and Feedback.
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgTrees size="1.65em" />
            </span>
            <p className="font-semibold text-2xl">
              Manufacturer's Representative
            </p>
            <p className="text-gray-500 text-xl">
              We provide professional marketing services to multiple
              manufacturers.
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgBriefcase size="1.55em" />
            </span>
            <p className="font-semibold text-2xl">Consultancy</p>
            <p className="text-gray-500 text-xl">
              Simplifying Processes Creatively, Project Management, Immigration,
              and HR Services
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgGlobeAlt size="1.65em" />
            </span>
            <p className="font-semibold text-2xl">Web</p>
            <p className="text-gray-500 text-xl">
              Web Development, Mobile Development, API Development
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgTv size="1.75em" />
            </span>
            <p className="font-semibold text-2xl">Media</p>
            <p className="text-gray-500 text-xl">Videography, Cinematography</p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgPen size="1.55em" />
            </span>
            <p className="font-semibold text-2xl">Design</p>
            <p className="text-gray-500 text-xl">
              Visual Branding, Brand Strategy, Web Design, Product/Service
              Conceptualization
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgUserList size="1.55em" />
            </span>
            <p className="font-semibold text-2xl">Skills Acquisition</p>
            <p className="text-gray-500 text-xl">
              Training, Workshops, and Seminars,
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgReadme size="1.65em" />
            </span>
            <p className="font-semibold text-2xl">Educational Services</p>
            <p className="text-gray-500 text-xl">
              Private Tutors, Vitual and Physical, Admission to Foreign
              Universities
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgReadme size="1.65em" />
            </span>
            <p className="font-semibold text-2xl">Social Media Management</p>
            <p className="text-gray-500 text-xl">
              Account Set-Up across all platforms- Facebook, Instagram, Twitter,
              WhatsApp, LinkedIn, Tik-Tok
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgReadme size="1.65em" />
            </span>
            <p className="font-semibold text-2xl">Logistics</p>
            <p className="text-gray-500 text-xl">
              Private Jet Bookings (local & international)
            </p>
            <p className="text-gray-500 text-xl">
              Armoured Vehicles Procurement
            </p>
            <p className="text-gray-500 text-xl">
              Accommodation Arrangements (local & international)
            </p>
            <p className="text-gray-500 text-xl">
              Overall Security (police & private)
            </p>
          </article>
          <article className="border p-8">
            <span className="inline-flex items-center justify-center mb-4 text-blue-500 bg-blue-50 w-[60px] h-[60px]">
              <CgReadme size="1.65em" />
            </span>
            <p className="font-semibold text-2xl">Imports and Export</p>
            <p className="text-gray-500 text-xl">Fast Moving Consumer Items</p>
            <p className="text-gray-500 text-xl">Building Materials</p>
            <p className="text-gray-500 text-xl">Vehicles</p>
            <p className="text-gray-500 text-xl">Specialized Equipments</p>
          </article>
        </div>
      </section>

      <section className="bg-white" id="clients">
        <div className="container px-8 py-16 md:py-32">
          <h2 className="text-3xl md:text-5xl md:leading-tight font-bold mb-8">
            Trusted By
            <span className="text-red-500">.</span>
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-7 gap-8 partners text-center items-center mb-6">
            <div className="">
              <StaticImage
                src="../images/clients/british.png"
                placeholder="tracedSVG"
                quality={95}
                alt="BC"
                formats={["auto", "webp", "avif"]}
                className="h-[80px] w-[140px] mb-4 md:mb-0 grayscale"
              />
            </div>
            <div className="">
              <StaticImage
                src="../images/clients/nsip.png"
                placeholder="tracedSVG"
                quality={95}
                alt=""
                formats={["auto", "webp", "avif"]}
                className="w-[100px] mb-4 md:mb-0 grayscale"
              />
            </div>
            <div className="">
              <StaticImage
                src="../images/clients/nfmb.png"
                placeholder="tracedSVG"
                quality={100}
                alt=""
                formats={["auto", "webp", "avif"]}
                className="w-[120px] mb-4 md:mb-0 grayscale"
              />
            </div>
            <div className="">
              <StaticImage
                src="../images/clients/smedan.png"
                placeholder="tracedSVG"
                quality={100}
                alt=""
                formats={["auto", "webp", "avif"]}
                className="w-[140px] mb-4 md:mb-0 grayscale"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
